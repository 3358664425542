
import Sidebar from "@/components/Nav/Sidebar/Sidebar.vue";

export default {
	components: {Sidebar},
	props: {},
	setup() {
		return {};
	}

}
